var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "attributes-group"
  }, _vm._l(_vm.ATTRIBUTES, function (attr) {
    return _c('div', {
      key: attr,
      staticClass: "popover-content-attr",
      class: `attr-${attr}`
    }, [_c('div', {
      staticClass: "group-content"
    }, [_c('span', {
      staticClass: "popover-content-attr-cell key",
      class: {
        'hasValue': _vm.hasSumValue(attr)
      }
    }, [_vm._v(_vm._s(`${_vm.$t(attr)}: `))]), _c('span', {
      staticClass: "popover-content-attr-cell label key-value value",
      class: {
        'green': _vm.hasSumValue(attr)
      }
    }, [_vm._v(_vm._s(`${_vm.stats.sum[attr]}`))]), _c('span', {
      staticClass: "popover-content-attr-cell label bold",
      class: {
        'hasValue': _vm.hasGearValue(attr)
      }
    }, [_vm._v(_vm._s(_vm.$t('gear')) + ":")]), _c('span', {
      staticClass: "popover-content-attr-cell label",
      class: {
        'hasValue': _vm.hasGearValue(attr)
      }
    }, [_vm._v(_vm._s(_vm.stats.gear[attr]))]), _c('span', {
      staticClass: "popover-content-attr-cell label bold",
      class: {
        'hasValue': _vm.hasClassBonus(attr)
      }
    }, [_vm._v(_vm._s(_vm.$t('classEquipBonus')) + ":")]), _c('span', {
      staticClass: "popover-content-attr-cell label",
      class: {
        'hasValue': _vm.hasClassBonus(attr)
      }
    }, [_vm._v(_vm._s(`${_vm.stats.classBonus[attr]}`))])])]);
  }), 0);

}
var staticRenderFns = []

export { render, staticRenderFns }