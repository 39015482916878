var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "buy-modal",
      "hide-header": true
    },
    on: {
      "change": function ($event) {
        return _vm.onChange($event);
      }
    }
  }, [_vm.withPin ? _c('span', {
    staticClass: "badge-dialog",
    attrs: {
      "tabindex": "0"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        $event.stopPropagation();
        return _vm.togglePinned();
      },
      "keypress": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        $event.preventDefault();
        $event.stopPropagation();
        return _vm.togglePinned();
      }
    }
  }, [_c('pin-badge', {
    attrs: {
      "pinned": _vm.isPinned
    }
  })], 1) : _vm._e(), _c('div', [_c('span', {
    staticClass: "svg-icon close-icon icon-16 color",
    attrs: {
      "aria-hidden": "true",
      "tabindex": "0"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.icons.close)
    },
    on: {
      "click": function ($event) {
        return _vm.hideDialog();
      },
      "keypress": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.hideDialog();
      }
    }
  })]), _vm.item != null ? _c('div', {
    staticClass: "content"
  }, [_c('div', {
    staticClass: "inner-content"
  }, [_vm._t("item", function () {
    return [_vm.showAvatar ? _c('div', [_c('avatar', {
      attrs: {
        "show-visual-buffs": false,
        "member": _vm.user,
        "avatar-only": true,
        "hide-class-badge": true,
        "with-background": true,
        "override-avatar-gear": _vm.getAvatarOverrides(_vm.item),
        "sprites-margin": '0px auto 0px -24px'
      }
    })], 1) : _vm.item.key === 'gem' ? _c('item', {
      staticClass: "flat bordered-item",
      attrs: {
        "item": _vm.item,
        "item-content-class": _vm.item.class,
        "show-popover": false
      }
    }) : _vm.item.key != 'gem' ? _c('item', {
      staticClass: "flat bordered-item",
      attrs: {
        "item": _vm.item,
        "item-content-class": _vm.item.class,
        "show-popover": false
      }
    }) : _vm._e()];
  }, {
    "item": _vm.item
  }), !_vm.showAvatar && _vm.user.items[_vm.item.purchaseType] ? _c('div', {
    staticClass: "owned",
    class: _vm.totalOwned
  }, [_c('span', {
    staticClass: "owned-text"
  }, [_vm._v(_vm._s(_vm.$t('owned')) + ": "), _c('span', {
    staticClass: "user-amount"
  }, [_vm._v(_vm._s(_vm.totalOwned))])])]) : _vm._e(), _c('h4', {
    staticClass: "title"
  }, [_vm._v(" " + _vm._s(_vm.itemText) + " ")]), _c('div', {
    staticClass: "item-notes"
  }, [_vm._v(" " + _vm._s(_vm.itemNotes) + " ")]), _vm._t("additionalInfo", function () {
    return [_vm.showAttributesGrid ? _c('equipmentAttributesGrid', {
      staticClass: "attributesGrid",
      attrs: {
        "item": _vm.item,
        "user": _vm.user
      }
    }) : _vm._e()];
  }, {
    "item": _vm.item
  }), _vm.item.value > 0 && !(_vm.item.key === 'gem' && _vm.gemsLeft < 1) ? _c('div', {
    staticClass: "purchase-amount"
  }, [_c('div', {
    staticClass: "item-cost justify-content-center my-3"
  }, [_c('span', {
    staticClass: "cost d-flex mx-auto",
    class: _vm.getPriceClass()
  }, [_c('span', {
    staticClass: "svg-icon icon-24 my-auto mr-1",
    attrs: {
      "aria-hidden": "true"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.icons[_vm.getPriceClass()])
    }
  }), _c('span', {
    staticClass: "my-auto",
    class: _vm.getPriceClass()
  }, [_vm._v(_vm._s(_vm.item.value))])])]), _vm.showAmountToBuy(_vm.item) ? _c('div', {
    staticClass: "how-many-to-buy"
  }, [_vm._v(" " + _vm._s(_vm.$t('howManyToBuy')) + " ")]) : _vm._e(), _vm.showAmountToBuy(_vm.item) ? _c('div', [_c('number-increment', {
    staticClass: "number-increment",
    on: {
      "updateQuantity": function ($event) {
        _vm.selectedAmountToBuy = $event;
      }
    }
  }), _c('div', {
    staticClass: "total",
    class: {
      'notEnough': _vm.notEnoughCurrency
    }
  }, [_c('span', {
    staticClass: "total-text"
  }, [_vm._v(_vm._s(_vm.$t('sendTotal')))]), _c('span', {
    staticClass: "svg-icon total icon-24",
    attrs: {
      "aria-hidden": "true"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.icons[_vm.getPriceClass()])
    }
  }), _c('span', {
    staticClass: "total-text",
    class: _vm.getPriceClass()
  }, [_vm._v(_vm._s(_vm.item.value * _vm.selectedAmountToBuy))])])], 1) : _vm._e()]) : _vm._e(), _vm.item.key === 'gem' && _vm.gemsLeft < 1 ? _c('div', {
    staticClass: "no-more-gems"
  }, [_vm._v(" " + _vm._s(_vm.$t('notEnoughGemsToBuy')) + " ")]) : _vm._e(), _vm.nonSubscriberHourglasses ? _c('div', {
    staticClass: "hourglass-nonsub mt-3"
  }, [_vm._v(" " + _vm._s(_vm.$t('mysticHourglassNeededNoSub')) + " ")]) : _vm._e(), _vm.getPriceClass() === 'gems' && !_vm.enoughCurrency(_vm.getPriceClass(), _vm.item.value * _vm.selectedAmountToBuy) ? _c('button', {
    staticClass: "btn btn-primary mb-3",
    on: {
      "click": function ($event) {
        return _vm.purchaseGems();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('purchaseGems')) + " ")]) : _vm.nonSubscriberHourglasses ? _c('button', {
    staticClass: "btn btn-primary",
    on: {
      "click": function ($event) {
        return _vm.viewSubscriptions(_vm.item);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('viewSubscriptions')) + " ")]) : !(_vm.item.key === 'gem' && _vm.gemsLeft < 1) ? _c('button', {
    staticClass: "btn btn-primary",
    class: {
      'notEnough': !_vm.preventHealthPotion || !_vm.enoughCurrency(_vm.getPriceClass(), _vm.item.value * _vm.selectedAmountToBuy)
    },
    attrs: {
      "disabled": _vm.item.key === 'gem' && _vm.gemsLeft === 0 || _vm.attemptingToPurchaseMoreGemsThanAreLeft || _vm.numberInvalid || _vm.item.locked || !_vm.preventHealthPotion || !_vm.enoughCurrency(_vm.getPriceClass(), _vm.item.value * _vm.selectedAmountToBuy),
      "tabindex": "0"
    },
    on: {
      "click": function ($event) {
        return _vm.buyItem();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('buyNow')) + " ")]) : _vm._e()], 2)]) : _vm._e(), _vm.item.end && _vm.item.owned == null ? _c('countdown-banner', {
    staticClass: "limitedTime available",
    attrs: {
      "end-date": _vm.endDate
    }
  }) : _vm._e(), _vm.item.key === 'rebirth_orb' && _vm.item.value > 0 && _vm.user.stats.lvl >= 100 ? _c('div', {
    staticClass: "free-rebirth d-flex align-items-center"
  }, [_c('div', {
    staticClass: "m-auto"
  }, [_c('span', {
    staticClass: "svg-icon inline icon-16 mr-2 pt-015",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.whiteClock)
    }
  }), _c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.$t('nextFreeRebirth', {
        days: _vm.nextFreeRebirth
      }))
    }
  })])]) : _vm._e(), _vm.item.key === 'gem' ? _c('div', {
    staticClass: "d-flex justify-content-center align-items-center"
  }, [_vm.gemsLeft > 0 ? _c('div', {
    staticClass: "gems-left d-flex justify-content-center align-items-center"
  }, [_c('strong', [_vm._v(_vm._s(_vm.$t('monthlyGems')) + "  ")]), _vm._v(" " + _vm._s(_vm.gemsLeft) + " / " + _vm._s(_vm.totalGems) + " " + _vm._s(_vm.$t('gemsRemaining')) + " ")]) : _vm._e(), _vm.gemsLeft === 0 ? _c('div', {
    staticClass: "out-of-gems-banner d-flex justify-content-center align-items-center"
  }, [_c('strong', [_vm._v(_vm._s(_vm.$t('monthlyGems')) + "  ")]), _vm._v(" " + _vm._s(_vm.gemsLeft) + " / " + _vm._s(_vm.totalGems) + " " + _vm._s(_vm.$t('gemsRemaining')) + " ")]) : _vm._e()]) : _vm._e(), _c('div', {
    attrs: {
      "slot": "modal-footer"
    },
    slot: "modal-footer"
  }, [_c('span', {
    staticClass: "user-balance ml-3 my-auto"
  }, [_vm._v(_vm._s(_vm.$t('yourBalance')))]), _c('balanceInfo', {
    staticClass: "mr-3",
    attrs: {
      "currency-needed": _vm.getPriceClass(),
      "amount-needed": _vm.item.value
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }