var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('base-notification', {
    attrs: {
      "can-remove": _vm.canRemove,
      "has-icon": false,
      "notification": _vm.notification
    },
    on: {
      "click": _vm.action
    }
  }, [_c('div', {
    attrs: {
      "slot": "content"
    },
    slot: "content"
  }, [_c('div', {
    staticClass: "message",
    domProps: {
      "innerHTML": _vm._s(_vm.$t('invitedToQuest', {
        quest: _vm.questName
      }))
    }
  }), _c('quest-info', {
    attrs: {
      "quest": _vm.questData,
      "small-version": true
    }
  }), _c('div', {
    staticClass: "notifications-buttons"
  }, [_c('div', {
    staticClass: "btn btn-small btn-success",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.questAccept();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('accept')) + " ")]), _c('div', {
    staticClass: "btn btn-small btn-danger",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.questReject();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('reject')) + " ")])])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }