var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "new-stuff",
      "size": "lg",
      "hide-header": true,
      "hide-footer": true,
      "no-close-on-esc": "",
      "no-close-on-backdrop": ""
    },
    on: {
      "shown": function ($event) {
        return _vm.onShow();
      }
    }
  }, [_c('div', {
    staticClass: "modal-body"
  }, [_c('news-content', {
    ref: "newsContent"
  })], 1), _c('div', {
    staticClass: "modal-footer d-flex align-items-center pb-0"
  }, [_c('button', {
    staticClass: "btn btn-secondary ml-auto",
    on: {
      "click": function ($event) {
        return _vm.tellMeLater();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('tellMeLater')) + " ")]), _c('button', {
    staticClass: "btn btn-primary",
    on: {
      "click": function ($event) {
        return _vm.dismissAlert();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('dismissAlert')) + " ")])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }