var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "select-user-modal",
      "hide-header": true,
      "hide-footer": !_vm.currentEvent || _vm.currentEvent.promo !== 'g1g1'
    },
    on: {
      "hide": function ($event) {
        return _vm.onHide();
      }
    }
  }, [_vm.currentEvent && _vm.currentEvent.promo === 'g1g1' ? _c('div', {
    staticClass: "g1g1 d-flex flex-column text-center justify-content-center align-items-center"
  }, [_c('h1', [_vm._v(" " + _vm._s(_vm.$t('g1g1')) + " ")]), _c('p', [_vm._v(" " + _vm._s(_vm.$t('g1g1Returning')) + " ")])]) : _c('h2', {
    staticClass: "d-flex flex-column mx-auto align-items-center"
  }, [_vm._v(" " + _vm._s(_vm.$t('sendAGift')) + " ")]), _vm.currentEvent && _vm.currentEvent.promo === 'g1g1' ? _c('div', {
    staticClass: "g1g1-margin d-flex flex-column align-items-center"
  }, [_vm._m(0)]) : _c('div', {
    staticClass: "d-flex flex-column align-items-center"
  }, [_vm._m(1)]), _c('div', {
    staticClass: "d-flex flex-column align-items-center"
  }, [_vm.currentEvent && _vm.currentEvent.promo === 'g1g1' ? _c('div', {
    staticClass: "g1g1-modal-close",
    on: {
      "click": function ($event) {
        return _vm.close();
      }
    }
  }, [_c('div', {
    staticClass: "g1g1-svg-icon",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.close)
    }
  })]) : _c('div', {
    staticClass: "modal-close",
    on: {
      "click": function ($event) {
        return _vm.close();
      }
    }
  }, [_c('div', {
    staticClass: "svg-icon",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.close)
    }
  })]), _c('div', {
    staticClass: "mx-auto mt-3"
  }, [_c('h3', [_vm._v(" " + _vm._s(_vm.$t('sendGiftToWhom')) + " ")])]), _c('div', {
    staticClass: "form",
    attrs: {
      "name": "selectUser",
      "novalidate": "novalidate"
    }
  }, [_c('validated-text-input', {
    attrs: {
      "id": "selectUser",
      "is-valid": _vm.foundUser._id,
      "placeholder": _vm.$t('usernameOrUserId'),
      "invalid-issues": _vm.userInputInvalidIssues
    },
    model: {
      value: _vm.userSearchTerm,
      callback: function ($$v) {
        _vm.userSearchTerm = $$v;
      },
      expression: "userSearchTerm"
    }
  }), _c('div', {
    staticClass: "d-flex flex-column justify-content-center align-items-middle mt-3"
  }, [_c('button', {
    staticClass: "btn btn-primary mx-auto mt-2",
    attrs: {
      "type": "submit",
      "disabled": _vm.searchCannotSubmit
    },
    on: {
      "click": function ($event) {
        return _vm.selectUser();
      }
    }
  }, [_vm.currentEvent && _vm.currentEvent.promo === 'g1g1' ? _c('div', [_vm._v(" " + _vm._s(_vm.$t('selectSubscription')) + " ")]) : _c('div', [_vm._v(" " + _vm._s(_vm.$t('next')) + " ")])]), _vm.currentEvent && _vm.currentEvent.promo === 'g1g1' ? _c('div', {
    staticClass: "g1g1-cancel d-flex justify-content-center",
    domProps: {
      "innerHTML": _vm._s(_vm.$t('cancel'))
    },
    on: {
      "click": function ($event) {
        return _vm.close();
      }
    }
  }) : _vm._e()])], 1)]), _c('div', {
    staticClass: "g1g1-fine-print text-center pt-3",
    attrs: {
      "slot": "modal-footer"
    },
    slot: "modal-footer"
  }, [_vm._m(2), _vm._m(3), _vm._m(4), _vm._m(5)])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "svg-big-gift",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.bigGift)
    }
  });
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "svg-big-gift",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.bigGift)
    }
  });
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('strong', [_vm._v(" " + _vm._s(_vm.$t('howItWorks')) + " ")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticClass: "mx-5 mt-1"
  }, [_vm._v(" " + _vm._s(_vm.$t('g1g1HowItWorks')) + " ")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('strong', [_vm._v(" " + _vm._s(_vm.$t('limitations')) + " ")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticClass: "mx-5 mt-1"
  }, [_vm._v(" " + _vm._s(_vm.$t('g1g1Limitations', {
    promoStartMonth: _vm.promoStartMonth,
    promoStartOrdinal: _vm.promoStartOrdinal,
    promoStartTime: _vm.promoStartTime,
    promoEndMonth: _vm.promoEndMonth,
    promoEndOrdinal: _vm.promoEndOrdinal,
    promoEndTime: _vm.promoEndTime
  })) + " ")]);

}]

export { render, staticRenderFns }