var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "gray-100"
  }, [_vm.showStatus ? _c('div', _vm._l(_vm.completionsList, function (completion) {
    return _c('div', {
      key: completion.userId,
      staticClass: "d-flex completion-row"
    }, [_c('div', {
      staticClass: "control"
    }, [_c('div', {
      staticClass: "inner d-flex justify-content-center align-items-center p-auto",
      class: {
        interactive: _vm.iconClass(completion) !== 'lock'
      },
      on: {
        "click": function ($event) {
          _vm.iconClass(completion) !== 'lock' ? _vm.needsWork(completion) : null;
        }
      }
    }, [_c('div', {
      staticClass: "icon",
      class: _vm.iconClass(completion),
      domProps: {
        "innerHTML": _vm._s(_vm.icons[_vm.iconClass(completion)])
      }
    })])]), _c('div', {
      staticClass: "px-75 py-2 info"
    }, [_c('div', [_c('strong', [_vm._v(" @" + _vm._s(completion.userName) + " ")])]), completion.completedDate ? _c('div', {
      class: {
        'green-10': completion.completed
      }
    }, [_vm._v(" " + _vm._s(completion.completedDateString) + " ")]) : _vm._e()])]);
  }), 0) : _vm._e(), _c('div', {
    staticClass: "claim-bottom-message d-flex align-items-center"
  }, [_vm.assignedUsersCount > 0 ? _c('div', {
    staticClass: "svg-icon ml-2 users-icon color",
    class: {
      'green-10': _vm.completionsCount === _vm.assignedUsersCount
    },
    domProps: {
      "innerHTML": _vm._s(_vm.icons.users)
    }
  }) : _vm._e(), _c('div', {
    staticClass: "mr-auto ml-1 my-auto",
    class: {
      'green-10': _vm.completionsCount === _vm.assignedUsersCount
    },
    domProps: {
      "innerHTML": _vm._s(_vm.message)
    }
  }), _vm.task.group.assignedUsers && ['daily', 'todo'].indexOf(_vm.task.type) !== -1 ? _c('div', {
    staticClass: "d-flex ml-auto mr-1 my-auto"
  }, [_vm.assignedUsersCount > 1 ? _c('span', {
    staticClass: "d-flex mr-1 my-auto"
  }, [!_vm.showStatus && _vm.completionsCount ? _c('span', {
    staticClass: "small-check"
  }, [_c('div', {
    staticClass: "svg-icon color",
    class: {
      'green-10': _vm.completionsCount === _vm.assignedUsersCount
    },
    domProps: {
      "innerHTML": _vm._s(_vm.icons.check)
    }
  })]) : _vm._e(), !_vm.showStatus && _vm.completionsCount ? _c('span', {
    staticClass: "ml-1 mr-2 my-auto",
    class: {
      'green-10': _vm.completionsCount === _vm.assignedUsersCount
    }
  }, [_vm._v(" " + _vm._s(_vm.completionsCount) + "/" + _vm._s(_vm.assignedUsersCount) + " ")]) : _vm._e(), _vm.assignedUsersCount > 1 && !_vm.showStatus ? _c('a', {
    on: {
      "click": function ($event) {
        _vm.showStatus = !_vm.showStatus;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('viewStatus')) + " ")]) : _vm._e(), _vm.showStatus ? _c('a', {
    on: {
      "click": function ($event) {
        _vm.showStatus = !_vm.showStatus;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('close')) + " ")]) : _vm._e()]) : _vm._e(), _vm.assignedUsersCount === 1 && _vm.task.type === 'daily' && !_vm.task.completed && _vm.singleAssignLastDone ? _c('span', {
    staticClass: "mr-1 d-inline-flex"
  }, [_c('span', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.bottom",
      value: _vm.$t('lastCompleted'),
      expression: "$t('lastCompleted')",
      modifiers: {
        "hover": true,
        "bottom": true
      }
    }],
    staticClass: "svg-icon color last-completed mr-1 my-auto",
    class: {
      'gray-200': _vm.completionsCount !== _vm.assignedUsersCount
    },
    domProps: {
      "innerHTML": _vm._s(_vm.icons.lastComplete)
    }
  }), _c('span', {
    class: {
      'green-10': _vm.completionsCount === _vm.assignedUsersCount
    }
  }, [_vm._v(" " + _vm._s(_vm.formattedCompletionTime) + " ")])]) : _vm._e()]) : _vm._e()])]);

}
var staticRenderFns = []

export { render, staticRenderFns }