var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "invite-modal",
      "title": _vm.$t(`inviteTo${_vm.groupType}`),
      "hide-footer": true
    }
  }, [_c('div', [_c('strong', [_vm._v(_vm._s(_vm.$t('inviteEmailUsername')))]), _c('div', {
    staticClass: "small"
  }, [_vm._v(" " + _vm._s(_vm.$t('inviteEmailUsernameInfo')) + " ")]), _vm._l(_vm.invites, function (invite, index) {
    return _c('div', [_c('div', {
      staticClass: "input-group"
    }, [index === _vm.invites.length - 1 && invite.text.length === 0 ? _c('div', {
      staticClass: "d-flex align-items-center justify-content-center"
    }, [_c('div', {
      staticClass: "svg-icon positive-icon",
      domProps: {
        "innerHTML": _vm._s(_vm.icons.positiveIcon)
      }
    })]) : _vm._e(), _c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: invite.text,
        expression: "invite.text"
      }],
      staticClass: "form-control",
      class: {
        'input-valid': invite.valid,
        'is-invalid input-invalid': invite.valid === false
      },
      attrs: {
        "type": "text",
        "placeholder": _vm.$t('emailOrUsernameInvite')
      },
      domProps: {
        "value": invite.text
      },
      on: {
        "keyup": _vm.expandInviteList,
        "input": [function ($event) {
          if ($event.target.composing) return;
          _vm.$set(invite, "text", $event.target.value);
        }, function ($event) {
          return _vm.inviteUpdated(invite);
        }]
      }
    })]), invite.error ? _c('div', {
      staticClass: "input-error text-center mt-2"
    }, [_vm._v(" " + _vm._s(invite.error) + " ")]) : _vm._e()]);
  })], 2), _c('div', {
    staticClass: "modal-footer d-flex justify-content-center"
  }, [_c('a', {
    staticClass: "mr-3",
    on: {
      "click": function ($event) {
        return _vm.close();
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('cancel')))]), _c('button', {
    staticClass: "btn btn-primary",
    class: {
      disabled: _vm.cannotSubmit
    },
    attrs: {
      "disabled": _vm.cannotSubmit
    },
    on: {
      "click": function ($event) {
        return _vm.sendInvites();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('sendInvitations')) + " ")])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }