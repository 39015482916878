var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('yesterdaily-modal', {
    attrs: {
      "yester-dailies": _vm.yesterDailies,
      "cron-action": _vm.runCronAction
    },
    on: {
      "hidden": function ($event) {
        return _vm.afterYesterdailies();
      }
    }
  }), _c('armoire-empty'), _c('new-stuff'), _c('death'), _c('low-health'), _c('level-up'), _c('choose-class'), _c('rebirth-enabled'), _c('contributor'), _c('won-challenge'), _c('ultimate-gear'), _c('streak'), _c('rebirth'), _c('joined-guild'), _c('joined-challenge'), _c('invited-friend'), _c('login-incentives', {
    attrs: {
      "data": _vm.notificationData
    }
  }), _c('quest-completed'), _c('quest-invitation'), _c('verify-username'), _vm.notificationData && _vm.notificationData.achievement ? _c('generic-achievement', {
    attrs: {
      "data": _vm.notificationData
    }
  }) : _vm._e(), _c('onboarding-complete'), _c('first-drops'), _c('group-plans-update')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }