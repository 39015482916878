var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "toggle ml-auto section-button",
    attrs: {
      "role": "button",
      "aria-expanded": _vm.visible,
      "tabindex": "0"
    },
    on: {
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.emitClick.apply(null, arguments);
      },
      "click": _vm.emitClick
    }
  }, [_vm.visible ? _c('span', {
    staticClass: "svg-icon icon-16",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.upIcon)
    }
  }) : _c('span', {
    staticClass: "svg-icon icon-16 down-icon color-stroke",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.downIcon)
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }