var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "profile",
      "hide-footer": true
    },
    on: {
      "hide": _vm.beforeHide,
      "shown": function ($event) {
        return _vm.onShown();
      }
    }
  }, [_c('div', {
    attrs: {
      "slot": "modal-header"
    },
    slot: "modal-header"
  }, [_c('close-x', {
    on: {
      "close": function ($event) {
        return _vm.close();
      }
    }
  })], 1), _c('profile', {
    staticStyle: {
      "margin-top": "24px"
    },
    attrs: {
      "user-id": _vm.userId,
      "starting-page": _vm.startingPage
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }