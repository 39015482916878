var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "won-challenge",
      "title": _vm.$t('wonChallenge'),
      "size": "sm",
      "hide-header": true
    }
  }, [_c('close-icon', {
    on: {
      "click": function ($event) {
        return _vm.close();
      }
    }
  }), _c('div', {
    staticClass: "text-center"
  }, [_vm._m(0), _c('div', {
    staticClass: "d-flex align-items-center justify-content-center mb-4"
  }, [_vm._m(1), _c('div', {
    staticClass: "achievement-karaoke-2x"
  }), _vm._m(2)]), _c('p', {
    staticClass: "mb-4 chal-desc",
    domProps: {
      "innerHTML": _vm._s(_vm.$t('wonChallengeDesc', {
        challengeName: _vm.challengeName
      }))
    }
  })]), _vm.notification ? _c('div', {
    staticClass: "pt-3 w-100",
    attrs: {
      "slot": "modal-footer"
    },
    slot: "modal-footer"
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-center mb-3"
  }, [_vm._m(3), _vm._m(4), _vm._m(5)]), _c('div', {
    staticClass: "d-flex align-items-center justify-content-center mb-4"
  }, [_vm._m(6), _c('strong', [_vm._v(_vm._s(_vm.notification.data.prize))])]), _vm._m(7)]) : _vm._e()], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h1', {
    staticClass: "header purple"
  }, [_vm._v(" " + _vm._s(_vm.$t('wonChallenge')) + " ")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "svg-icon sparkles sparkles-rotate",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.sparkles)
    }
  });
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "svg-icon sparkles",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.sparkles)
    }
  });
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "svg-icon stars",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.stars)
    }
  });
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('strong', [_vm._v(_vm._s(_vm.$t('yourReward')))]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "svg-icon stars stars-rotate",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.stars)
    }
  });
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "svg-icon gem mr-1",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.gem)
    }
  });
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('button', {
    staticClass: "btn btn-primary",
    on: {
      "click": function ($event) {
        return _vm.close();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('onwards')) + " ")]);

}]

export { render, staticRenderFns }