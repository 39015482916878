var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('remove-member-modal', {
    attrs: {
      "member-to-remove": _vm.memberToRemove,
      "group-id": _vm.groupId
    },
    on: {
      "member-removed": _vm.memberRemoved
    }
  }), _c('b-modal', {
    attrs: {
      "id": "members-modal",
      "title": _vm.$t('createGuild'),
      "size": "md",
      "hide-footer": true
    }
  }, [_c('div', {
    staticClass: "header-wrap",
    attrs: {
      "slot": "modal-header"
    },
    slot: "modal-header"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-6"
  }, [_vm._m(0)]), _c('div', {
    staticClass: "col-6"
  }, [_c('button', {
    staticClass: "close",
    attrs: {
      "type": "button",
      "aria-label": "Close"
    },
    on: {
      "click": function ($event) {
        return _vm.close();
      }
    }
  }, [_c('span', {
    attrs: {
      "aria-hidden": "true"
    }
  }, [_vm._v("×")])])])]), _c('div', {
    staticClass: "row d-flex align-items-center"
  }, [_c('div', {
    staticClass: "col-4"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.searchTerm,
      expression: "searchTerm"
    }],
    staticClass: "form-control input-search",
    attrs: {
      "type": "text",
      "placeholder": _vm.$t('search')
    },
    domProps: {
      "value": _vm.searchTerm
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.searchTerm = $event.target.value;
      }
    }
  })]), _c('div', {
    staticClass: "col-5"
  }, [_c('select-list', {
    attrs: {
      "items": _vm.sortOptions,
      "value": _vm.optionEntryBySelectedValue,
      "key-prop": "value"
    },
    on: {
      "select": function ($event) {
        return _vm.changeSortOption($event);
      }
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function (_ref) {
        var item = _ref.item;
        return [item ? _c('span', {
          staticClass: "label"
        }, [_vm._v(_vm._s(item.text))]) : _vm._e()];
      }
    }])
  })], 1), _c('div', {
    staticClass: "col-3"
  }, [_c('select-list', {
    attrs: {
      "items": _vm.sortDirections,
      "value": _vm.directionEntryBySelectedValue,
      "key-prop": "value"
    },
    on: {
      "select": function ($event) {
        return _vm.changeSortDirection($event);
      }
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [item ? _c('span', {
          staticClass: "label"
        }, [_vm._v(_vm._s(item.text))]) : _vm._e()];
      }
    }])
  })], 1)])]), _vm.sortDirty && _vm.group.type === 'party' ? _c('div', {
    staticClass: "row apply-options d-flex justify-content-center"
  }, [_c('a', {
    on: {
      "click": function ($event) {
        return _vm.applySortOptions();
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('applySortToHeader')))])]) : _vm._e(), _vm.invites.length > 0 ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-6 offset-3 nav mt-2 mb-3"
  }, [_c('div', {
    staticClass: "nav-item",
    class: {
      active: _vm.selectedPage === 'members'
    },
    on: {
      "click": function ($event) {
        return _vm.viewMembers();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('members')) + " ")]), _c('div', {
    staticClass: "nav-item",
    class: {
      active: _vm.selectedPage === 'invites'
    },
    on: {
      "click": function ($event) {
        return _vm.viewInvites();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('invites')) + " ")])])]) : _vm._e(), _vm.loading ? _c('loading-gryphon') : _vm._e(), _vm.selectedPage === 'members' && !_vm.loading ? _c('div', {
    class: {
      'mt-1': _vm.invites.length === 0
    }
  }, [_vm._l(_vm.sortedMembers, function (member, index) {
    return _c('div', {
      key: member._id,
      staticClass: "row"
    }, [_c('div', {
      staticClass: "col-11 pl-0"
    }, [_c('member-details', {
      staticClass: "ml-4",
      attrs: {
        "member": member,
        "class-badge-position": 'next-to-name'
      }
    })], 1), _c('div', {
      staticClass: "col-1 actions"
    }, [_c('b-dropdown', {
      attrs: {
        "right": "right"
      }
    }, [_c('div', {
      staticClass: "svg-icon inline dots pt-1",
      attrs: {
        "slot": "button-content"
      },
      domProps: {
        "innerHTML": _vm._s(_vm.icons.dots)
      },
      slot: "button-content"
    }), _c('b-dropdown-item', {
      on: {
        "click": function ($event) {
          return _vm.sendMessage(member);
        }
      }
    }, [_c('span', {
      staticClass: "dropdown-icon-item"
    }, [_c('div', {
      staticClass: "svg-icon inline",
      domProps: {
        "innerHTML": _vm._s(_vm.icons.messageIcon)
      }
    }), _c('span', {
      staticClass: "text"
    }, [_vm._v(_vm._s(_vm.$t('sendMessage')))])])]), _vm.shouldShowLeaderFunctions(member._id) ? _c('b-dropdown-item', {
      on: {
        "click": function ($event) {
          return _vm.promoteToLeader(member);
        }
      }
    }, [_c('span', {
      staticClass: "dropdown-icon-item"
    }, [_c('div', {
      staticClass: "svg-icon inline",
      domProps: {
        "innerHTML": _vm._s(_vm.icons.starIcon)
      }
    }), _c('span', {
      staticClass: "text"
    }, [_vm._v(_vm._s(_vm.$t('promoteToLeader')))])])]) : _vm._e(), _vm.shouldShowAddManager(member._id) ? _c('b-dropdown-item', {
      on: {
        "click": function ($event) {
          return _vm.addManager(member._id);
        }
      }
    }, [_c('span', {
      staticClass: "dropdown-icon-item"
    }, [_c('div', {
      staticClass: "svg-icon inline",
      domProps: {
        "innerHTML": _vm._s(_vm.icons.starIcon)
      }
    }), _c('span', {
      staticClass: "text"
    }, [_vm._v(_vm._s(_vm.$t('addManager')))])])]) : _vm._e(), _vm.shouldShowRemoveManager(member._id) ? _c('b-dropdown-item', {
      on: {
        "click": function ($event) {
          return _vm.removeManager(member._id);
        }
      }
    }, [_c('span', {
      staticClass: "dropdown-icon-item"
    }, [_c('div', {
      staticClass: "svg-icon inline block-icon",
      domProps: {
        "innerHTML": _vm._s(_vm.icons.blockIcon)
      }
    }), _c('span', {
      staticClass: "text"
    }, [_vm._v(_vm._s(_vm.$t('removeManager2')))])])]) : _vm._e(), _vm.challengeId ? _c('b-dropdown-item', {
      on: {
        "click": function ($event) {
          return _vm.viewProgress(member);
        }
      }
    }, [_c('span', {
      staticClass: "dropdown-icon-item"
    }, [_c('span', {
      staticClass: "text"
    }, [_vm._v(_vm._s(_vm.$t('viewProgress')))])])]) : _vm._e(), _vm.shouldShowLeaderFunctions(member._id) ? _c('b-dropdown-item', {
      on: {
        "click": function ($event) {
          return _vm.removeMember(member, index);
        }
      }
    }, [_c('span', {
      staticClass: "dropdown-icon-item"
    }, [_c('div', {
      staticClass: "svg-icon inline block-icon",
      domProps: {
        "innerHTML": _vm._s(_vm.icons.blockIcon)
      }
    }), _c('span', {
      staticClass: "text"
    }, [_vm._v(_vm._s(_vm.$t('removeMember')))])])]) : _vm._e()], 1)], 1)]);
  }), _vm.isLoadMoreAvailable ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12 text-center"
  }, [_c('button', {
    staticClass: "btn btn-secondary",
    on: {
      "click": function ($event) {
        return _vm.loadMoreMembers();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('loadMore')) + " ")])])]) : _vm._e(), _vm.members.length > 3 ? _c('div', {
    staticClass: "row gradient"
  }) : _vm._e()], 2) : _vm._e(), _vm.selectedPage === 'invites' && !_vm.loading ? _c('div', _vm._l(_vm.invites, function (member, index) {
    return _c('div', {
      key: member._id,
      staticClass: "row"
    }, [_c('div', {
      staticClass: "col-11 pl-0"
    }, [_c('member-details', {
      attrs: {
        "member": member
      }
    })], 1), _c('div', {
      staticClass: "col-1 actions"
    }, [_c('b-dropdown', {
      attrs: {
        "right": "right"
      }
    }, [_c('div', {
      staticClass: "svg-icon inline dots",
      attrs: {
        "slot": "button-content"
      },
      domProps: {
        "innerHTML": _vm._s(_vm.icons.dots)
      },
      slot: "button-content"
    }), _vm.isLeader ? _c('b-dropdown-item', {
      on: {
        "click": function ($event) {
          return _vm.removeInvite(member, index);
        }
      }
    }, [_c('span', {
      staticClass: "dropdown-icon-item"
    }, [_vm.isLeader ? _c('div', {
      staticClass: "svg-icon inline block-icon",
      domProps: {
        "innerHTML": _vm._s(_vm.icons.blockIcon)
      }
    }) : _vm._e(), _c('span', {
      staticClass: "text"
    }, [_vm._v(_vm._s(_vm.$t('removeInvite')))])])]) : _vm._e()], 1)], 1)]);
  }), 0) : _vm._e()], 1)], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h1', [_vm._v(" " + _vm._s(_vm.$t('members')) + " ")]);

}]

export { render, staticRenderFns }