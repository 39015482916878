var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "send-gift",
      "hide-footer": true,
      "hide-header": true,
      "size": "md"
    },
    on: {
      "hide": function ($event) {
        return _vm.onHide();
      }
    }
  }, [_c('div', [_c('div', {
    staticClass: "modal-close",
    on: {
      "click": function ($event) {
        return _vm.close();
      }
    }
  }, [_c('div', {
    staticClass: "icon-close",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.closeIcon)
    }
  })]), _c('div', [_c('h2', {
    staticClass: "d-flex flex-column mx-auto align-items-center"
  }, [_vm._v(" " + _vm._s(_vm.$t('sendAGift')) + " ")])]), _vm.userReceivingGift ? _c('div', {
    staticClass: "modal-body"
  }, [_c('avatar', {
    staticClass: "d-flex flex-column mx-auto align-items-center",
    attrs: {
      "member": _vm.userReceivingGift,
      "hide-class-badge": true
    }
  }), _c('div', {
    staticClass: "avatar-spacer"
  }), _c('div', {
    staticClass: "d-flex flex-column mx-auto align-items-center display-name"
  }, [_c('user-link', {
    class: _vm.display - _vm.name,
    attrs: {
      "user-id": _vm.displayName,
      "name": _vm.displayName,
      "backer": _vm.userBacker,
      "contributor": _vm.userContributor
    }
  })], 1), _c('div', {
    staticClass: "d-flex flex-column mx-auto align-items-center user-name"
  }, [_vm._v(" @" + _vm._s(_vm.userName) + " ")])], 1) : _vm._e(), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-8 offset-md-2 text-center nav"
  }, [_c('div', {
    staticClass: "nav-link",
    class: {
      active: _vm.selectedPage === 'subscription'
    },
    on: {
      "click": function ($event) {
        return _vm.selectPage('subscription');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('subscription')) + " ")]), _c('div', {
    staticClass: "nav-link",
    class: {
      active: _vm.selectedPage !== 'subscription'
    },
    on: {
      "click": function ($event) {
        return _vm.selectPage('buyGems');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('gems')) + " ")])])]), _c('subscription-options', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.selectedPage === 'subscription',
      expression: "selectedPage === 'subscription'"
    }],
    staticClass: "subscribe-option",
    attrs: {
      "user-receiving-gift": _vm.userReceivingGift,
      "receiver-name": _vm.receiverName
    }
  }), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.selectedPage === 'buyGems',
      expression: "selectedPage === 'buyGems'"
    }]
  }, [_c('div', {
    staticClass: "gem-group"
  }, [_vm._m(0), _c('div', {
    staticClass: "d-flex flex-row align-items-center justify-content-center"
  }, [_c('div', {
    staticClass: "gray-circle",
    on: {
      "click": function ($event) {
        _vm.gift.gems.amount <= 0 ? _vm.gift.gems.amount = 0 : _vm.gift.gems.amount--;
      }
    }
  }, [_c('div', {
    staticClass: "icon-negative",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.negativeIcon)
    }
  })]), _c('div', {
    staticClass: "input-group"
  }, [_c('div', {
    staticClass: "input-group-prepend input-group-icon align-items-center"
  }, [_c('div', {
    staticClass: "icon-gem",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.gemIcon)
    }
  })]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.number",
      value: _vm.gift.gems.amount,
      expression: "gift.gems.amount",
      modifiers: {
        "number": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "id": "gemsForm",
      "max": "9999"
    },
    domProps: {
      "value": _vm.gift.gems.amount
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.gift.gems, "amount", _vm._n($event.target.value));
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  })]), _c('div', {
    staticClass: "gray-circle",
    on: {
      "click": function ($event) {
        _vm.gift.gems.amount++;
      }
    }
  }, [_c('div', {
    staticClass: "icon-positive",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.positiveIcon)
    }
  })])]), _c('div', {
    staticClass: "buy-gem-total"
  }, [_vm._v(" " + _vm._s(_vm.$t('sendTotal')) + " ")]), _c('div', {
    staticClass: "buy-gem-amount"
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.formatter.format(_vm.totalGems)) + " ")])]), _c('div', {
    staticClass: "gem-state-change",
    class: {
      active: _vm.selectedPage === 'ownGems'
    },
    on: {
      "click": function ($event) {
        return _vm.selectPage('ownGems');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('wantToSendOwnGems')) + " ")])]), _c('payments-buttons', {
    staticClass: "payment-buttons",
    attrs: {
      "stripe-fn": function () {
        return _vm.redirectToStripe({
          gift: _vm.gift,
          uuid: _vm.userReceivingGift._id,
          receiverName: _vm.receiverName
        });
      },
      "paypal-fn": function () {
        return _vm.openPaypalGift({
          gift: _vm.gift,
          giftedTo: _vm.userReceivingGift._id,
          receiverName: _vm.receiverName
        });
      },
      "amazon-data": {
        type: 'single',
        gift: _vm.gift,
        giftedTo: _vm.userReceivingGift._id,
        receiverName: _vm.receiverName
      }
    }
  })], 1)], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.selectedPage === 'ownGems',
      expression: "selectedPage === 'ownGems'"
    }]
  }, [_c('div', {
    staticClass: "gem-group"
  }, [_vm._m(1), _c('div', {
    staticClass: "d-flex align-items-center justify-content-center"
  }, [_c('div', {
    staticClass: "gray-circle",
    on: {
      "click": function ($event) {
        _vm.gift.gems.amount <= 0 ? _vm.gift.gems.amount = 0 : _vm.gift.gems.amount--;
      }
    }
  }, [_c('div', {
    staticClass: "icon-negative",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.negativeIcon)
    }
  })]), _c('div', {
    staticClass: "input-group"
  }, [_c('div', {
    staticClass: "input-group-prepend input-group-icon align-items-center"
  }, [_c('div', {
    staticClass: "icon-gem",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.gemIcon)
    }
  })]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.gift.gems.amount,
      expression: "gift.gems.amount"
    }],
    staticClass: "form-control",
    attrs: {
      "id": "gemsForm",
      "max": _vm.maxGems
    },
    domProps: {
      "value": _vm.gift.gems.amount
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.gift.gems, "amount", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "gray-circle",
    on: {
      "click": function ($event) {
        _vm.gift.gems.amount < _vm.maxGems ? _vm.gift.gems.amount++ : _vm.gift.gems.amount = _vm.maxGems;
      }
    }
  }, [_c('div', {
    staticClass: "icon-positive",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.positiveIcon)
    }
  })])]), _c('div', {
    staticClass: "align-items-middle"
  }, [_c('div', {
    staticClass: "d-flex justify-content-center align-items-middle"
  }, [_c('span', {
    staticClass: "balance-text"
  }, [_vm._v(" " + _vm._s(_vm.$t('yourBalance')) + " ")]), _c('span', {
    staticClass: "icon-gem balance-gem-margin",
    staticStyle: {
      "display": "inline-block"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.icons.gemIcon)
    }
  }), _c('span', {
    staticClass: "balance-gems"
  }, [_vm._v(" " + _vm._s(_vm.maxGems) + " ")])])]), _c('div', {
    staticClass: "d-flex flex-column justify-content-center align-items-middle mt-3"
  }, [_vm.fromBal ? _c('button', {
    staticClass: "btn btn-primary mx-auto mt-2",
    attrs: {
      "type": "submit",
      "disabled": _vm.sendingInProgress
    },
    on: {
      "click": function ($event) {
        return _vm.sendGift();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("send")) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "gem-state-change",
    class: {
      active: _vm.selectedPage === 'buyGems'
    },
    on: {
      "click": function ($event) {
        return _vm.selectPage('buyGems');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('needToPurchaseGems')) + " ")])])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('label', [_vm._v(" " + _vm._s(_vm.$t('howManyGemsPurchase')) + " ")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('label', [_vm._v(" " + _vm._s(_vm.$t('howManyGemsSend')) + " ")]);

}]

export { render, staticRenderFns }