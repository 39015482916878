var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [!_vm.user && _vm.userLoaded ? _c('div', [_c('error404')], 1) : _vm.userLoaded ? _c('div', {
    staticClass: "profile mt-n3"
  }, [_c('div', {
    staticClass: "header"
  }, [_c('div', {
    staticClass: "avatar mr-auto"
  }, [_c('member-details', {
    staticClass: "mx-4",
    attrs: {
      "member": _vm.user,
      "class-badge-position": 'hidden'
    }
  })], 1)]), _c('div', {
    staticClass: "state-pages pt-3"
  }, [_vm.userBlocked ? _c('div', {
    staticClass: "blocked-banned text-center mb-3 mx-4 py-2 px-3",
    domProps: {
      "innerHTML": _vm._s(_vm.$t('blockedUser'))
    }
  }) : _vm._e(), _vm.hasPermission(_vm.userLoggedIn, 'moderator') && _vm.hero.auth.blocked ? _c('div', {
    staticClass: "blocked-banned mb-3 mx-4 py-2 px-3 d-flex align-items-middle justify-content-center"
  }, [_vm._m(0), _c('div', {
    staticClass: "my-auto mr-auto",
    domProps: {
      "innerHTML": _vm._s(_vm.$t('bannedUser'))
    }
  })]) : _vm._e(), _c('div', {
    staticClass: "text-center nav"
  }, [_c('div', {
    staticClass: "nav-item",
    class: {
      active: _vm.selectedPage === 'profile'
    },
    on: {
      "click": function ($event) {
        return _vm.selectPage('profile');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('profile')) + " ")]), _c('div', {
    staticClass: "nav-item",
    class: {
      active: _vm.selectedPage === 'stats'
    },
    on: {
      "click": function ($event) {
        return _vm.selectPage('stats');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('stats')) + " ")]), _c('div', {
    staticClass: "nav-item",
    class: {
      active: _vm.selectedPage === 'achievements'
    },
    on: {
      "click": function ($event) {
        return _vm.selectPage('achievements');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('achievements')) + " ")])])]), _vm.user.profile ? _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.selectedPage === 'profile',
      expression: "selectedPage === 'profile'"
    }],
    staticClass: "standard-page",
    attrs: {
      "id": "userProfile"
    }
  }, [!_vm.editing ? _c('div', {
    staticClass: "flex-container"
  }, [_c('div', {
    staticClass: "flex-left"
  }, [_c('div', {
    staticClass: "about mb-0"
  }, [_c('h2', [_vm._v(_vm._s(_vm.$t('about')))])]), _c('div', {
    staticClass: "flex-left"
  }, [_c('div', {
    staticClass: "about profile-section"
  }, [_vm.user.profile.blurb ? _c('p', {
    directives: [{
      name: "markdown",
      rawName: "v-markdown",
      value: _vm.user.profile.blurb,
      expression: "user.profile.blurb"
    }],
    staticClass: "markdown"
  }) : _c('p', [_vm._v(" " + _vm._s(_vm.$t('noDescription')) + " ")])])]), _c('div', {
    staticClass: "photo profile-section"
  }, [_c('h2', [_vm._v(_vm._s(_vm.$t('photo')))]), _vm.user.profile.imageUrl ? _c('img', {
    staticClass: "img-rendering-auto",
    attrs: {
      "src": _vm.user.profile.imageUrl
    }
  }) : _c('p', [_vm._v(" " + _vm._s(_vm.$t('noPhoto')) + " ")])])]), _c('div', {
    staticClass: "ml-auto"
  }, [_vm.user._id === _vm.userLoggedIn._id ? _c('button', {
    staticClass: "btn btn-primary flex-right edit-profile",
    on: {
      "click": function ($event) {
        _vm.editing = !_vm.editing;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('editProfile')) + " ")]) : _vm.user._id !== _vm.userLoggedIn._id ? _c('span', {
    staticClass: "flex-right d-flex justify-content-between"
  }, [_c('router-link', {
    attrs: {
      "to": {
        path: '/private-messages',
        query: {
          uuid: _vm.user._id
        }
      },
      "replace": ""
    }
  }, [_c('button', {
    staticClass: "btn btn-primary send-message"
  }, [_vm._v(" " + _vm._s(_vm.$t('sendMessage')) + " ")])]), _c('b-dropdown', {
    staticClass: "mx-auto",
    attrs: {
      "right": "right",
      "toggle-class": "with-icon",
      "no-caret": true
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_vm._m(1)];
      },
      proxy: true
    }], null, false, 3315621669)
  }, [_c('b-dropdown-item', {
    staticClass: "selectListItem",
    on: {
      "click": function ($event) {
        return _vm.openSendGemsModal();
      }
    }
  }, [_c('span', {
    staticClass: "with-icon"
  }, [_vm._m(2), _vm._m(3)])]), _c('b-dropdown-item', {
    staticClass: "selectListItem",
    class: {
      disabled: !_vm.canReport
    },
    attrs: {
      "disabled": !_vm.canReport
    },
    on: {
      "click": function ($event) {
        return _vm.reportPlayer();
      }
    }
  }, [_c('span', {
    staticClass: "with-icon"
  }, [_vm._m(4), _vm._m(5)])]), !_vm.userBlocked ? _c('b-dropdown-item', {
    staticClass: "selectListItem block-ban",
    nativeOn: {
      "!click": function ($event) {
        $event.stopPropagation();
        return _vm.blockUser();
      }
    }
  }, [_c('span', {
    staticClass: "with-icon"
  }, [_vm._m(6), _vm._m(7)])]) : _c('b-dropdown-item', {
    staticClass: "selectListItem block-ban",
    nativeOn: {
      "!click": function ($event) {
        $event.stopPropagation();
        return _vm.unblockUser();
      }
    }
  }, [_c('span', {
    staticClass: "with-icon"
  }, [_vm._m(8), _vm._m(9)])]), _vm.hasPermission(_vm.userLoggedIn, 'moderator') ? _c('div', [_c('div', {
    staticClass: "admin-tools-divider"
  }, [_vm._m(10)]), _vm.hasPermission(_vm.userLoggedIn, 'userSupport') ? _c('b-dropdown-item', {
    staticClass: "selectListItem",
    on: {
      "click": function ($event) {
        return _vm.openAdminPanel();
      }
    }
  }, [_c('span', {
    staticClass: "with-icon"
  }, [_vm._m(11), _vm._m(12)])]) : _vm._e(), !_vm.hero.auth.blocked ? _c('b-dropdown-item', {
    staticClass: "selectListItem block-ban",
    nativeOn: {
      "!click": function ($event) {
        $event.stopPropagation();
        return _vm.adminToggleBan();
      }
    }
  }, [_c('span', {
    staticClass: "with-icon"
  }, [_vm._m(13), _vm._m(14)])]) : _c('b-dropdown-item', {
    staticClass: "selectListItem block-ban",
    nativeOn: {
      "!click": function ($event) {
        $event.stopPropagation();
        return _vm.adminToggleBan();
      }
    }
  }, [_c('span', {
    staticClass: "with-icon"
  }, [_vm._m(15), _vm._m(16)])]), _c('b-dropdown-item', {
    staticClass: "selectListItem",
    nativeOn: {
      "!click": function ($event) {
        $event.stopPropagation();
        return _vm.adminToggleShadowMute();
      }
    }
  }, [_c('span', {
    staticClass: "with-icon"
  }, [_vm._m(17), _vm._m(18), _c('toggle-switch', {
    staticClass: "toggle-switch-outer ml-auto",
    nativeOn: {
      "!change": function ($event) {
        $event.stopPropagation();
        return _vm.adminToggleShadowMute();
      }
    },
    model: {
      value: _vm.hero.flags.chatShadowMuted,
      callback: function ($$v) {
        _vm.$set(_vm.hero.flags, "chatShadowMuted", $$v);
      },
      expression: "hero.flags.chatShadowMuted"
    }
  })], 1)]), _c('b-dropdown-item', {
    staticClass: "selectListItem",
    nativeOn: {
      "!click": function ($event) {
        $event.stopPropagation();
        return _vm.adminToggleChatRevoke();
      }
    }
  }, [_c('span', {
    staticClass: "with-icon"
  }, [_vm._m(19), _vm._m(20), _c('toggle-switch', {
    staticClass: "toggle-switch-outer ml-auto",
    nativeOn: {
      "!change": function ($event) {
        $event.stopPropagation();
        return _vm.adminToggleChatRevoke();
      }
    },
    model: {
      value: _vm.hero.flags.chatRevoked,
      callback: function ($$v) {
        _vm.$set(_vm.hero.flags, "chatRevoked", $$v);
      },
      expression: "hero.flags.chatRevoked"
    }
  })], 1)])], 1) : _vm._e()], 1)], 1) : _vm._e(), _c('div', {
    staticClass: "info profile-section"
  }, [_c('div', {
    staticClass: "info-item"
  }, [_c('div', {
    staticClass: "info-item-label"
  }, [_vm._v(" " + _vm._s(_vm.$t('joined')) + ": ")]), _c('div', {
    staticClass: "info-item-value"
  }, [_vm._v(" " + _vm._s(_vm.userJoinedDate) + " ")])]), _c('div', {
    staticClass: "info-item"
  }, [_c('div', {
    staticClass: "info-item-label"
  }, [_vm._v(" " + _vm._s(_vm.$t('totalLogins')) + ": ")]), _c('div', {
    staticClass: "info-item-value"
  }, [_vm._v(" " + _vm._s(_vm.user.loginIncentives) + " ")])]), _c('div', {
    staticClass: "info-item"
  }, [_c('div', {
    staticClass: "info-item-label"
  }, [_vm._v(" " + _vm._s(_vm.$t('latestCheckin')) + ": ")]), _c('div', {
    staticClass: "info-item-value"
  }, [_vm._v(" " + _vm._s(_vm.userLastLoggedIn) + " ")])]), _c('div', {
    staticClass: "info-item"
  }, [_c('div', {
    staticClass: "info-item-label"
  }, [_vm._v(" " + _vm._s(_vm.$t('nextReward')) + ": ")]), _c('div', {
    staticClass: "info-item-value"
  }, [_vm._v(" " + _vm._s(_vm.getNextIncentive()) + " " + _vm._s(_vm.getNextIncentive() === 1 ? _vm.$t('day') : _vm.$t('days')) + " ")])])])])]) : _vm._e(), _vm.editing ? _c('div', {
    staticClass: "row"
  }, [_c('h1', [_vm._v(_vm._s(_vm.$t('editProfile')))]), _c('div', {}, [_c('div', {
    staticClass: "alert alert-info alert-sm",
    domProps: {
      "innerHTML": _vm._s(_vm.$t('communityGuidelinesWarning', _vm.managerEmail))
    }
  }), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v(_vm._s(_vm.$t('displayName')))]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.editingProfile.name,
      expression: "editingProfile.name"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "placeholder": _vm.$t('fullName')
    },
    domProps: {
      "value": _vm.editingProfile.name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.editingProfile, "name", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v(_vm._s(_vm.$t('photoUrl')))]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.editingProfile.imageUrl,
      expression: "editingProfile.imageUrl"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "url",
      "placeholder": _vm.$t('imageUrl')
    },
    domProps: {
      "value": _vm.editingProfile.imageUrl
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.editingProfile, "imageUrl", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v(_vm._s(_vm.$t('about')))]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.editingProfile.blurb,
      expression: "editingProfile.blurb"
    }],
    staticClass: "form-control",
    attrs: {
      "rows": "5",
      "placeholder": _vm.$t('displayBlurbPlaceholder')
    },
    domProps: {
      "value": _vm.editingProfile.blurb
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.editingProfile, "blurb", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "text-center"
  }, [_c('button', {
    staticClass: "btn btn-primary",
    on: {
      "click": function ($event) {
        return _vm.save();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("save")) + " ")]), _c('button', {
    staticClass: "btn btn-secondary",
    on: {
      "click": function ($event) {
        _vm.editing = false;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")])])]) : _vm._e()]) : _vm._e(), _vm.user.achievements ? _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.selectedPage === 'achievements',
      expression: "selectedPage === 'achievements'"
    }],
    staticClass: "standard-page container",
    attrs: {
      "id": "achievements"
    }
  }, [_vm._l(_vm.achievements, function (category, key) {
    return _c('div', {
      key: key,
      staticClass: "row category-row d-flex flex-column"
    }, [_c('h3', {
      staticClass: "text-center"
    }, [_vm._v(" " + _vm._s(_vm.$t(`${key}Achievs`)) + " ")]), _c('div', {}, [_c('div', {
      staticClass: "row achievements-row justify-content-center"
    }, _vm._l(_vm.achievementsCategory(key, category), function (achievement, achievKey) {
      return _c('div', {
        key: achievKey,
        staticClass: "achievement-wrapper col text-center"
      }, [_c('div', {
        staticClass: "box achievement-container d-flex justify-content-center align-items-middle",
        class: {
          'achievement-unearned': !achievement.earned
        },
        attrs: {
          "id": achievKey + '-achievement'
        }
      }, [_c('b-popover', {
        attrs: {
          "target": '#' + achievKey + '-achievement',
          "triggers": "hover",
          "placement": "top"
        }
      }, [_c('h4', {
        staticClass: "popover-content-title"
      }, [_vm._v(" " + _vm._s(achievement.title) + " ")]), _c('div', {
        staticClass: "popover-content-text",
        domProps: {
          "innerHTML": _vm._s(achievement.text)
        }
      })]), achievement.earned ? _c('div', {
        staticClass: "achievement m-auto",
        class: achievement.icon + '2x'
      }, [achievement.optionalCount ? _c('div', {
        staticClass: "counter badge badge-pill stack-count"
      }, [_vm._v(" " + _vm._s(achievement.optionalCount) + " ")]) : _vm._e()]) : _vm._e(), !achievement.earned ? _c('div', {
        staticClass: "achievement achievement-unearned achievement-unearned2x m-auto"
      }) : _vm._e()], 1)]);
    }), 0), _vm.achievementsCategories[key].number > 5 ? _c('div', {
      staticClass: "btn btn-flat btn-show-more",
      on: {
        "click": function ($event) {
          return _vm.toggleAchievementsCategory(key);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.achievementsCategories[key].open ? _vm.$t('hideAchievements', {
      category: _vm.$t(`${key}Achievs`)
    }) : _vm.$t('showAllAchievements', {
      category: _vm.$t(`${key}Achievs`)
    })) + " ")]) : _vm._e()])]);
  }), _c('hr', {}), _c('div', {
    staticClass: "row"
  }, [_vm.user.achievements.challenges ? _c('div', {
    staticClass: "col-12 col-md-6"
  }, [_c('div', {
    staticClass: "achievement-icon achievement-karaoke-2x"
  }), _c('h3', {
    staticClass: "text-center"
  }, [_vm._v(" " + _vm._s(_vm.$t('challengesWon')) + " ")]), _vm._l(_vm.user.achievements.challenges, function (chal) {
    return _c('div', {
      key: chal,
      staticClass: "achievement-list-item"
    }, [_c('span', {
      directives: [{
        name: "markdown",
        rawName: "v-markdown",
        value: chal,
        expression: "chal"
      }]
    })]);
  })], 2) : _vm._e(), _vm.user.achievements.quests ? _c('div', {
    staticClass: "col-12 col-md-6"
  }, [_c('div', {
    staticClass: "achievement-icon achievement-alien2x"
  }), _c('h3', {
    staticClass: "text-center"
  }, [_vm._v(" " + _vm._s(_vm.$t('questsCompleted')) + " ")]), _vm._l(_vm.user.achievements.quests, function (value, key) {
    return _c('div', {
      key: key,
      staticClass: "achievement-list-item d-flex justify-content-between"
    }, [_c('span', [_vm._v(_vm._s(_vm.content.quests[key].text()))]), value > 1 ? _c('span', {
      staticClass: "badge badge-pill stack-count"
    }, [_vm._v(" " + _vm._s(value) + " ")]) : _vm._e()]);
  })], 2) : _vm._e()])], 2) : _vm._e(), _c('div', [_vm.user.preferences ? _c('profileStats', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.selectedPage === 'stats',
      expression: "selectedPage === 'stats'"
    }],
    attrs: {
      "user": _vm.user,
      "show-allocation": _vm.showAllocation()
    }
  }) : _vm._e()], 1)]) : _vm._e()]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "svg-icon icon-16 color my-auto ml-auto mr-2",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.block)
    }
  });
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "svg-icon dots-icon with-icon",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.dots)
    }
  });
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "svg-icon icon-16 color",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.gift)
    }
  });
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "send-gift"
  }, [_vm._v(" " + _vm._s(_vm.$t('sendGift')) + " ")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "svg-icon icon-16 color",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.report)
    }
  });
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', [_vm._v(" " + _vm._s(_vm.$t('reportPlayer')) + " ")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "svg-icon icon-16 color",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.block)
    }
  });
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', [_vm._v(" " + _vm._s(_vm.$t('blockPlayer')) + " ")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "svg-icon icon-16 color",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.block)
    }
  });
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', [_vm._v(" " + _vm._s(_vm.$t('unblock')) + " ")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', [_c('strong', [_vm._v(_vm._s(_vm.$t('adminTools')))])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "svg-icon icon-16 color",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.crown)
    }
  });
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', [_vm._v(" " + _vm._s(_vm.$t('viewAdminPanel')) + " ")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "svg-icon icon-16 color",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.block)
    }
  });
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', [_vm._v(" " + _vm._s(_vm.$t('banPlayer')) + " ")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "svg-icon icon-16 color",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.block)
    }
  });
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', [_vm._v(" " + _vm._s(_vm.$t('unbanPlayer')) + " ")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "svg-icon icon-16 color",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.shadowMute)
    }
  });
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "admin-action"
  }, [_vm._v(" " + _vm._s(_vm.$t('shadowMute')) + " ")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "svg-icon icon-16 color",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.mute)
    }
  });
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', [_vm._v(" " + _vm._s(_vm.$t('mutePlayer')) + " ")]);

}]

export { render, staticRenderFns }