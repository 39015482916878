var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('button', {
    class: {
      'purple': _vm.purple
    },
    style: {
      '--icon-color': _vm.iconColor,
      '--icon-color-hover': _vm.iconColorHover
    },
    attrs: {
      "title": "close dialog"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('click', $event);
      }
    }
  }, [_vm._m(0)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "svg-icon",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.close)
    }
  });

}]

export { render, staticRenderFns }