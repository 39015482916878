var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "onboarding-guide-panel d-flex align-items-center flex-column p-4 dropdown-separated",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        $event.preventDefault();
        return _vm.action.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "svg-icon onboarding-toggle",
    class: {
      'onboarding-toggle-open': _vm.open
    },
    domProps: {
      "innerHTML": _vm._s(_vm.icons.down)
    },
    on: {
      "click": _vm.openPanel
    }
  }), _vm._m(0), _vm._m(1), _vm._m(2), _c('div', {
    staticClass: "onboarding-progress-box d-flex flex-row justify-content-between small-text mb-2"
  }, [_vm._m(3), _c('span', {
    class: {
      'has-progress': _vm.progress > 0
    }
  }, [_vm._v(_vm._s(_vm.progressText))])]), _c('div', {
    staticClass: "onboarding-progress-bar mb-3"
  }, [_c('div', {
    staticClass: "onboarding-progress-bar-fill",
    style: {
      width: `${_vm.progress}%`
    }
  })]), _c('b-collapse', {
    attrs: {
      "id": "onboardingPanelCollapse"
    },
    model: {
      value: _vm.open,
      callback: function ($$v) {
        _vm.open = $$v;
      },
      expression: "open"
    }
  }, _vm._l(_vm.onboardingAchievements, function (achievement, key) {
    return _c('div', {
      key: key,
      staticClass: "achievement-box d-flex flex-row",
      class: {
        'achievement-earned': achievement.earned
      }
    }, [_c('div', {
      staticClass: "achievement-icon-wrapper"
    }, [_c('div', {
      class: `achievement-icon ${_vm.getAchievementIcon(achievement)}`
    })]), _c('div', {
      staticClass: "achievement-info d-flex flex-column"
    }, [_vm._o(_c('strong', {
      staticClass: "achievement-title"
    }, [_vm._v(_vm._s(achievement.title))]), 0, key), _vm._o(_c('span', {
      staticClass: "small-text achievement-desc"
    }, [_vm._v(_vm._s(_vm.getAchievementText(key)))]), 1, key)])]);
  }), 0)], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "svg-icon onboarding-guide-banner",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.onboardingGuideBanner)
    }
  });
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h3', {
    staticClass: "getting-started"
  }, [_vm._v(" " + _vm._s(_vm.$t('gettingStarted')) + " ")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "getting-started-desc",
    domProps: {
      "innerHTML": _vm._s(_vm.$t('gettingStartedDesc'))
    }
  });
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('strong', [_vm._v(_vm._s(_vm.$t('yourProgress')))]);

}]

export { render, staticRenderFns }