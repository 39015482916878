var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "remove-member",
      "title": _vm.$t('removeMember'),
      "size": "md",
      "hide-footer": true
    }
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('h2', {
    staticClass: "col-12"
  }, [_vm._v(" " + _vm._s(_vm.$t('sureKick')) + " ")]), _vm.memberToRemove.profile ? _c('div', {
    staticClass: "col-12 removing-member"
  }, [_vm._v(" " + _vm._s(_vm.memberToRemove.profile.name) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "modal-body"
  }, [_c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.removeMessage,
      expression: "removeMessage"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "rows": "5",
      "placeholder": _vm.$t('optionalMessage')
    },
    domProps: {
      "value": _vm.removeMessage
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.removeMessage = $event.target.value;
      }
    }
  })]), _c('div', {
    staticClass: "modal-footer"
  }, [_c('button', {
    staticClass: "pull-left btn btn-danger",
    on: {
      "click": function ($event) {
        return _vm.confirmRemoveMember();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('yesRemove')) + " ")]), _c('button', {
    staticClass: "btn btn-secondary",
    on: {
      "click": function ($event) {
        return _vm.close();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('cancel')) + " ")])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }