var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "amazon-payment",
      "title": "Amazon",
      "size": "md",
      "hide-footer": true
    },
    on: {
      "hide": function ($event) {
        return _vm.reset();
      }
    }
  }, [_c('h2', {
    staticClass: "text-center"
  }, [_vm._v(" Continue with Amazon ")]), _vm.amazonPayments.loggedIn ? _c('div', {
    staticStyle: {
      "width": "400px",
      "height": "228px"
    },
    attrs: {
      "id": "AmazonPayWallet"
    }
  }) : _vm._e(), _vm.amazonPayments.loggedIn && _vm.amazonPayments.type === 'subscription' ? [_c('br'), _c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.$t('amazonPaymentsRecurring'))
    }
  }), _c('div', {
    staticStyle: {
      "width": "400px",
      "height": "140px"
    },
    attrs: {
      "id": "AmazonPayRecurring"
    }
  })] : _vm._e(), _c('div', {
    staticClass: "modal-footer"
  }, [_c('div', {
    staticClass: "text-center"
  }, [_vm.amazonPaymentsCanCheckout ? _c('button', {
    staticClass: "btn btn-primary",
    attrs: {
      "disabled": !_vm.amazonButtonEnabled
    },
    on: {
      "click": function ($event) {
        return _vm.amazonCheckOut();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('checkout')) + " ")]) : _vm._e()])])], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }