var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "first-drops",
      "size": "md",
      "hide-header": true,
      "hide-footer": true
    }
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('div', {
    staticClass: "modal-close",
    on: {
      "click": function ($event) {
        return _vm.close();
      }
    }
  }, [_vm._m(0)]), _vm._m(1), _c('div', {
    staticClass: "d-flex justify-content-center"
  }, [_c('Sprite', {
    staticClass: "item-box ml-auto mr-3",
    attrs: {
      "image-name": _vm.eggClass
    }
  }), _c('Sprite', {
    staticClass: "item-box mr-auto",
    attrs: {
      "image-name": _vm.potionClass
    }
  })], 1), _vm._m(2), _vm._m(3), _vm._m(4)])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "svg-icon",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.close)
    }
  });
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h2', {
    staticClass: "mt-3 mb-4"
  }, [_vm._v(" " + _vm._s(_vm.$t('foundNewItems')) + " ")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticClass: "mt-4"
  }, [_vm._v(" " + _vm._s(_vm.$t('foundNewItemsExplanation')) + " ")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticClass: "strong mb-4"
  }, [_vm._v(" " + _vm._s(_vm.$t('foundNewItemsCTA')) + " ")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('button', {
    staticClass: "btn btn-primary mb-2",
    on: {
      "click": function ($event) {
        return _vm.toInventory();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('letsgo')) + " ")]);

}]

export { render, staticRenderFns }