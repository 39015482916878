var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    staticClass: "d-flex",
    attrs: {
      "id": "verify-username",
      "size": "m",
      "no-close-on-backdrop": true,
      "no-close-on-esc": true,
      "hide-header": true,
      "hide-footer": true
    },
    on: {
      "hide": function ($event) {
        return _vm.$emit('hide');
      }
    }
  }, [_c('div', {
    staticClass: "nametag-header",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.helloNametag)
    }
  }), _c('h2', {
    staticClass: "text-center"
  }, [_vm._v(" " + _vm._s(_vm.$t('usernameTime')) + " ")]), _c('p', {
    staticClass: "text-center",
    domProps: {
      "innerHTML": _vm._s(_vm.$t('usernameInfo'))
    }
  }), _c('username-form'), _c('div', {
    staticClass: "scene_veteran_pets center-block"
  }), _c('div', {
    staticClass: "small text-center mb-3"
  }, [_vm._v(" " + _vm._s(_vm.$t('verifyUsernameVeteranPet')) + " ")]), _c('div', {
    staticClass: "small text-center tos-footer",
    domProps: {
      "innerHTML": _vm._s(_vm.$t('usernameTOSRequirements'))
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }