var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    ref: "container",
    staticClass: "progress-container",
    class: {
      condensed: _vm.condensed
    },
    attrs: {
      "id": _vm.elementId
    }
  }, [_vm.showIcon ? _c('div', {
    staticClass: "svg-icon",
    domProps: {
      "innerHTML": _vm._s(_vm.icon)
    }
  }) : _vm._e(), _c('div', {
    staticClass: "progress"
  }, [_c('div', {
    staticClass: "progress-bar",
    class: _vm.progressClass,
    style: {
      width: `${_vm.percent(_vm.value, _vm.maxValue)}%`
    }
  })]), _vm.showNumbers ? _c('span', {
    staticClass: "small-text"
  }, [_vm._v(" " + _vm._s(_vm._f("statFloor")(_vm.value)) + " / " + _vm._s(_vm.maxValue) + " ")]) : _vm._e(), _c('b-tooltip', {
    staticClass: "myClass",
    attrs: {
      "target": function () {
        return _vm.$refs.container;
      },
      "container": _vm.elementId,
      "title": _vm.tooltip,
      "triggers": "hover",
      "placement": "bottom"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }