var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.item.locked ? _c('div', [_c('h4', {
    staticClass: "popover-content-title"
  }, [_vm._v(" " + _vm._s(`${_vm.$t('lockedItem')}`) + " ")]), _vm.isWrongClass ? _c('div', {
    staticClass: "popover-content-text"
  }, [_vm._v(" " + _vm._s(`${_vm.$t('classLockedItem')}`) + " ")]) : _c('div', {
    staticClass: "popover-content-text"
  }, [_vm._v(" " + _vm._s(`${_vm.$t('tierLockedItem')}`) + " ")]), _c('p')]) : _c('div', [_c('h4', {
    staticClass: "popover-content-title"
  }, [_vm._v(" " + _vm._s(_vm.itemText) + " ")]), _c('div', {
    staticClass: "popover-content-text"
  }, [_vm._v(" " + _vm._s(_vm.itemNotes) + " ")]), _c('attributesGrid', {
    attrs: {
      "user": _vm.user,
      "item": _vm.item
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }