var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-row align-items-center justify-content-center number-increment"
  }, [_c('div', {
    staticClass: "gray-circle",
    on: {
      "click": function ($event) {
        _vm.quantity <= 0 ? _vm.quantity = 0 : _vm.quantity--;
      }
    }
  }, [_c('div', {
    staticClass: "icon-negative",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.svgNegative)
    }
  })]), _c('div', {
    staticClass: "input-group"
  }, [_c('div', {
    staticClass: "align-items-center"
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.quantity,
      expression: "quantity"
    }],
    staticClass: "form-control alignment",
    attrs: {
      "step": "1",
      "type": "number"
    },
    domProps: {
      "value": _vm.quantity
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.quantity = $event.target.value;
      }
    }
  })]), _c('div', {
    staticClass: "gray-circle",
    on: {
      "click": function ($event) {
        _vm.quantity++;
      }
    }
  }, [_c('div', {
    staticClass: "icon-positive",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.svgPositive)
    }
  })])]);

}
var staticRenderFns = []

export { render, staticRenderFns }