var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.canShow ? _c('div', {
    staticClass: "habitica-top-banner d-flex justify-content-between align-items-center",
    class: _vm.bannerClass,
    style: {
      height: _vm.height
    }
  }, [_vm._t("content"), _vm.canClose ? _c('close-x', {
    on: {
      "close": function ($event) {
        return _vm.close();
      }
    }
  }) : _vm._e()], 2) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }