var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "subscription-canceled-modal",
      "size": "sm",
      "hide-footer": true,
      "modal-class": ['modal-hidden-footer']
    }
  }, [_c('div', {
    attrs: {
      "slot": "modal-header"
    },
    slot: "modal-header"
  }, [_vm._m(0), _c('div', {
    staticClass: "icon-container check-container d-flex align-items-center justify-content-center"
  }, [_vm._m(1)])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12 modal-body-col"
  }, [_c('h2', [_vm._v(_vm._s(_vm.$t(_vm.isGroup ? 'canceledGroupPlan' : 'subCanceledTitle')))]), _c('div', {
    staticClass: "details-block"
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t('subWillBecomeInactive')) + " "), _c('br'), _c('strong', [_vm._v(_vm._s(_vm.isGroup ? _vm.groupDateTerminated : _vm.dateTerminated))])])]), _vm._m(2)])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "svg-icon close",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.close)
    },
    on: {
      "click": function ($event) {
        return _vm.close();
      }
    }
  });
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "svg-icon check",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.check)
    }
  });
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "auto-renew small-text"
  }, [_vm._v(_vm._s(_vm.$t('paymentCanceledDisputes')))]);

}]

export { render, staticRenderFns }