var render = function render(){
  var _class;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "task-wrapper",
    attrs: {
      "draggable": ""
    }
  }, [_c('div', {
    staticClass: "task transition",
    class: [{
      'groupTask': _vm.task.group.id,
      'task-not-editable': !_vm.teamManagerAccess,
      'task-not-scoreable': _vm.showTaskLockIcon,
      'link-exempt': !_vm.isChallengeTask && !_vm.isGroupTask
    }, `type_${_vm.task.type}`],
    on: {
      "click": function ($event) {
        return _vm.castEnd($event, _vm.task);
      }
    }
  }, [_c('div', {
    staticClass: "d-flex",
    class: {
      'task-not-scoreable': _vm.showTaskLockIcon
    }
  }, [_vm.task.type === 'habit' ? _c('div', {
    staticClass: "left-control d-flex justify-content-center pt-3",
    class: [{
      'control-bottom-box': _vm.task.group.id && !_vm.isOpenTask,
      'control-top-box': _vm.approvalsClass
    }, _vm.controlClass.up.bg]
  }, [_c('div', {
    staticClass: "task-control habit-control",
    class: [{
      'habit-control-positive-enabled': _vm.task.up && !_vm.showTaskLockIcon,
      'habit-control-positive-disabled': !_vm.task.up && !_vm.showTaskLockIcon,
      'task-not-scoreable': _vm.showTaskLockIcon
    }, _vm.controlClass.up.inner],
    attrs: {
      "tabindex": "0",
      "role": "button",
      "aria-label": _vm.$t('scoreUp'),
      "aria-disabled": _vm.showTaskLockIcon || !_vm.task.up && !_vm.showTaskLockIcon
    },
    on: {
      "click": function ($event) {
        return _vm.score('up');
      },
      "keypress": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.score('up');
      }
    }
  }, [_vm.showTaskLockIcon ? _c('div', {
    staticClass: "svg-icon lock",
    class: _vm.task.up ? _vm.controlClass.up.icon : 'positive',
    domProps: {
      "innerHTML": _vm._s(_vm.icons.lock)
    }
  }) : _c('div', {
    staticClass: "svg-icon positive",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.positive)
    }
  })])]) : _vm._e(), _vm.task.type === 'daily' || _vm.task.type === 'todo' ? _c('div', {
    staticClass: "left-control d-flex justify-content-center",
    class: [{
      'control-bottom-box': _vm.task.group.id && !_vm.isOpenTask,
      'control-top-box': _vm.approvalsClass
    }, _vm.controlClass.bg]
  }, [_c('div', {
    staticClass: "task-control daily-todo-control",
    class: [{
      'task-not-scoreable': _vm.showTaskLockIcon
    }, _vm.controlClass.inner],
    attrs: {
      "tabindex": "0",
      "role": "checkbox"
    },
    on: {
      "click": function ($event) {
        return _vm.score(_vm.showCheckIcon ? 'down' : 'up');
      },
      "keypress": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.score(_vm.showCheckIcon ? 'down' : 'up');
      }
    }
  }, [_vm.showTaskLockIcon ? _c('div', {
    staticClass: "svg-icon lock",
    class: _vm.controlClass.icon,
    domProps: {
      "innerHTML": _vm._s(_vm.icons.lock)
    }
  }) : _c('div', {
    staticClass: "svg-icon check",
    class: (_class = {
      'display-check-icon': _vm.showCheckIcon
    }, _class[_vm.controlClass.checkbox] = true, _class),
    domProps: {
      "innerHTML": _vm._s(_vm.icons.check)
    }
  })])]) : _vm._e(), _c('div', {
    staticClass: "task-content",
    class: _vm.contentClass
  }, [_c('div', {
    staticClass: "task-clickable-area pt-1 pl-75 pb-0",
    class: {
      'cursor-auto': !_vm.teamManagerAccess
    },
    attrs: {
      "tabindex": "0"
    },
    on: {
      "click": function ($event) {
        return _vm.edit($event, _vm.task);
      },
      "keypress": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.edit($event, _vm.task);
      }
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('h3', {
    directives: [{
      name: "markdown",
      rawName: "v-markdown",
      value: _vm.task.text,
      expression: "task.text"
    }],
    staticClass: "task-title markdown",
    class: {
      'has-notes': _vm.task.notes
    }
  }), !_vm.isRunningYesterdailies && _vm.showOptions ? _c('menu-dropdown', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.top",
      value: _vm.$t('options'),
      expression: "$t('options')",
      modifiers: {
        "hover": true,
        "top": true
      }
    }],
    ref: "taskDropdown",
    staticClass: "task-dropdown mr-1",
    attrs: {
      "tabindex": "0",
      "right": _vm.task.type === 'reward'
    }
  }, [_c('div', {
    attrs: {
      "slot": "dropdown-toggle"
    },
    slot: "dropdown-toggle"
  }, [_c('div', {
    staticClass: "svg-icon dropdown-icon",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.menu)
    }
  })]), _c('div', {
    attrs: {
      "slot": "dropdown-content"
    },
    slot: "dropdown-content"
  }, [_vm.showEdit ? _c('div', {
    ref: "editTaskItem",
    staticClass: "dropdown-item edit-task-item",
    attrs: {
      "tabindex": "0"
    },
    on: {
      "keypress": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.edit($event, _vm.task);
      }
    }
  }, [_c('span', {
    staticClass: "dropdown-icon-item"
  }, [_c('span', {
    staticClass: "svg-icon inline edit-icon",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.edit)
    }
  }), _c('span', {
    staticClass: "text"
  }, [_vm._v(_vm._s(_vm.$t('edit')))])])]) : _vm._e(), _c('div', {
    staticClass: "dropdown-item",
    attrs: {
      "tabindex": "0"
    },
    on: {
      "click": _vm.moveToTop,
      "keypress": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.moveToTop.apply(null, arguments);
      }
    }
  }, [_c('span', {
    staticClass: "dropdown-icon-item"
  }, [_c('span', {
    staticClass: "svg-icon inline push-to-top",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.top)
    }
  }), _c('span', {
    staticClass: "text"
  }, [_vm._v(_vm._s(_vm.$t('taskToTop')))])])]), _c('div', {
    staticClass: "dropdown-item",
    attrs: {
      "tabindex": "0"
    },
    on: {
      "click": _vm.moveToBottom,
      "keypress": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.moveToBottom.apply(null, arguments);
      }
    }
  }, [_c('span', {
    staticClass: "dropdown-icon-item"
  }, [_c('span', {
    staticClass: "svg-icon inline push-to-bottom",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.bottom)
    }
  }), _c('span', {
    staticClass: "text"
  }, [_vm._v(_vm._s(_vm.$t('taskToBottom')))])])]), _vm.showDelete ? _c('div', {
    staticClass: "dropdown-item",
    attrs: {
      "tabindex": "0"
    },
    on: {
      "click": _vm.destroy,
      "keypress": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.destroy.apply(null, arguments);
      }
    }
  }, [_c('span', {
    staticClass: "dropdown-icon-item delete-task-item"
  }, [_c('span', {
    staticClass: "svg-icon inline delete",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.delete)
    }
  }), _c('span', {
    staticClass: "text"
  }, [_vm._v(_vm._s(_vm.$t('delete')))])])]) : _vm._e()])]) : _vm._e()], 1), _c('div', {
    directives: [{
      name: "markdown",
      rawName: "v-markdown",
      value: _vm.task.notes,
      expression: "task.notes"
    }],
    staticClass: "task-notes small-text",
    class: {
      'has-checklist': _vm.task.notes && _vm.hasChecklist
    }
  })]), _vm.canViewchecklist ? _c('div', {
    staticClass: "checklist",
    class: {
      isOpen: !_vm.task.collapseChecklist
    }
  }, [_c('div', {
    staticClass: "d-inline-flex"
  }, [_c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.right",
      value: _vm.$t(`${_vm.task.collapseChecklist ? 'expand' : 'collapse'}Checklist`),
      expression: "$t(`${task.collapseChecklist\n                ? 'expand': 'collapse'}Checklist`)",
      modifiers: {
        "hover": true,
        "right": true
      }
    }],
    staticClass: "collapse-checklist mb-2 d-flex align-items-center expand-toggle",
    class: {
      open: !_vm.task.collapseChecklist
    },
    attrs: {
      "tabindex": "0"
    },
    on: {
      "click": function ($event) {
        return _vm.collapseChecklist(_vm.task);
      },
      "keypress": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.collapseChecklist(_vm.task);
      }
    }
  }, [_vm._m(0), _c('span', [_vm._v(_vm._s(_vm.checklistProgress))])])]), _vm._l(_vm.task.checklist, function (item) {
    return !_vm.task.collapseChecklist ? _c('div', {
      key: item.id,
      staticClass: "custom-control custom-checkbox checklist-item",
      class: {
        'checklist-item-done': item.completed,
        'cursor-auto': _vm.showTaskLockIcon
      }
    }, [_c('input', {
      staticClass: "custom-control-input",
      attrs: {
        "id": `checklist-${item.id}-${_vm.random}`,
        "tabindex": "0",
        "type": "checkbox",
        "disabled": _vm.castingSpell || _vm.showTaskLockIcon
      },
      domProps: {
        "checked": item.completed
      },
      on: {
        "change": function ($event) {
          return _vm.toggleChecklistItem(item);
        },
        "keypress": function ($event) {
          if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
          return _vm.toggleChecklistItem(item);
        }
      }
    }), _c('label', {
      directives: [{
        name: "markdown",
        rawName: "v-markdown",
        value: item.text,
        expression: "item.text"
      }],
      staticClass: "custom-control-label",
      class: {
        'cursor-auto': _vm.showTaskLockIcon
      },
      attrs: {
        "for": `checklist-${item.id}-${_vm.random}`
      }
    })]) : _vm._e();
  })], 2) : _vm._e(), _c('div', {
    staticClass: "icons small-text d-flex align-items-center"
  }, [_vm.task.type === 'todo' && _vm.task.date ? _c('div', {
    staticClass: "d-flex align-items-center",
    class: {
      'due-overdue': _vm.checkIfOverdue()
    }
  }, [_c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.bottom",
      value: _vm.$t('dueDate'),
      expression: "$t('dueDate')",
      modifiers: {
        "hover": true,
        "bottom": true
      }
    }],
    staticClass: "svg-icon calendar my-auto",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.calendar)
    }
  }), _c('span', [_vm._v(_vm._s(_vm.formatDueDate()))])]) : _vm._e(), _c('div', {
    staticClass: "icons-right d-flex justify-content-end"
  }, [_vm.showStreak ? _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.bottom",
      value: _vm.task.type === 'daily' ? _vm.$t('streakCounter') : _vm.$t('counter'),
      expression: "task.type === 'daily'\n                  ? $t('streakCounter') : $t('counter')",
      modifiers: {
        "hover": true,
        "bottom": true
      }
    }],
    staticClass: "svg-icon streak",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.streak)
    }
  }), _vm.task.type === 'daily' ? _c('span', [_vm._v(_vm._s(_vm.task.streak))]) : _vm._e(), _vm.task.type === 'habit' ? _c('span', [_vm.task.up && _vm.task.counterUp != 0 && _vm.task.down ? _c('span', {
    staticClass: "m-0"
  }, [_vm._v("+" + _vm._s(_vm.task.counterUp))]) : _vm.task.counterUp != 0 && _vm.task.counterDown == 0 ? _c('span', {
    staticClass: "m-0"
  }, [_vm._v(_vm._s(_vm.task.counterUp))]) : _vm.task.up ? _c('span', {
    staticClass: "m-0"
  }, [_vm._v("0")]) : _vm._e(), _vm.task.up && _vm.task.down ? _c('span', {
    staticClass: "m-0"
  }, [_vm._v(" | ")]) : _vm._e(), _vm.task.down && _vm.task.counterDown != 0 && _vm.task.up ? _c('span', {
    staticClass: "m-0"
  }, [_vm._v("-" + _vm._s(_vm.task.counterDown))]) : _vm.task.counterDown != 0 && _vm.task.counterUp == 0 ? _c('span', {
    staticClass: "m-0"
  }, [_vm._v(_vm._s(_vm.task.counterDown))]) : _vm.task.down ? _c('span', {
    staticClass: "m-0"
  }, [_vm._v("0")]) : _vm._e()]) : _vm._e()]) : _vm._e(), _vm.task.challenge && _vm.task.challenge.id ? _c('div', {
    staticClass: "d-flex align-items-center"
  }, [!_vm.task.challenge.broken ? _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.bottom",
      value: _vm.shortName,
      expression: "shortName",
      modifiers: {
        "hover": true,
        "bottom": true
      }
    }],
    staticClass: "svg-icon challenge",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.challenge)
    }
  }) : _vm._e(), _vm.task.challenge.broken ? _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.bottom",
      value: _vm.$t('brokenChaLink'),
      expression: "$t('brokenChaLink')",
      modifiers: {
        "hover": true,
        "bottom": true
      }
    }],
    staticClass: "svg-icon challenge broken",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.brokenChallengeIcon)
    },
    on: {
      "click": function ($event) {
        return _vm.handleBrokenTask(_vm.task);
      }
    }
  }) : _vm._e()]) : _vm._e(), _vm.hasTags && !_vm.task.group.id ? _c('div', {
    staticClass: "d-flex align-items-center",
    attrs: {
      "id": `tags-icon-${_vm.task._id}`
    }
  }, [_c('div', {
    staticClass: "svg-icon tags",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.tags)
    }
  })]) : _vm._e(), _vm.hasTags && !_vm.task.group.id ? _c('b-popover', {
    attrs: {
      "target": `tags-icon-${_vm.task._id}`,
      "triggers": "hover",
      "placement": "bottom"
    }
  }, [_c('div', {
    staticClass: "tags-popover"
  }, [_c('div', {
    staticClass: "d-flex align-items-center tags-container"
  }, [_vm._m(1), _vm._l(_vm.getTagsFor(_vm.task), function (tag) {
    return _c('div', {
      directives: [{
        name: "markdown",
        rawName: "v-markdown",
        value: tag,
        expression: "tag"
      }],
      key: tag,
      staticClass: "tag-label"
    });
  })], 2)])]) : _vm._e()], 1)])]), _vm.task.type === 'habit' ? _c('div', {
    staticClass: "right-control d-flex justify-content-center pt-3",
    class: [{
      'control-bottom-box': _vm.task.group.id && !_vm.isOpenTask,
      'control-top-box': _vm.approvalsClass
    }, _vm.controlClass.down.bg]
  }, [_c('div', {
    staticClass: "task-control habit-control",
    class: [{
      'habit-control-negative-enabled': _vm.task.down && !_vm.showTaskLockIcon,
      'habit-control-negative-disabled': !_vm.task.down && !_vm.showTaskLockIcon,
      'task-not-scoreable': _vm.showTaskLockIcon
    }, _vm.controlClass.down.inner],
    attrs: {
      "tabindex": "0",
      "role": "button",
      "aria-label": _vm.$t('scoreDown'),
      "aria-disabled": _vm.showTaskLockIcon || !_vm.task.down && !_vm.showTaskLockIcon
    },
    on: {
      "click": function ($event) {
        return _vm.score('down');
      },
      "keypress": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.score('down');
      }
    }
  }, [_vm.showTaskLockIcon ? _c('div', {
    staticClass: "svg-icon lock",
    class: _vm.task.down ? _vm.controlClass.down.icon : 'negative',
    domProps: {
      "innerHTML": _vm._s(_vm.icons.lock)
    }
  }) : _c('div', {
    staticClass: "svg-icon negative",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.negative)
    }
  })])]) : _vm._e(), _vm.task.type === 'reward' ? _c('div', {
    staticClass: "right-control d-flex align-items-center justify-content-center reward-control",
    class: [{
      'task-not-scoreable': _vm.showTaskLockIcon
    }, _vm.controlClass.bg],
    attrs: {
      "tabindex": "0"
    },
    on: {
      "click": function ($event) {
        return _vm.score('down');
      },
      "keypress": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.score('down');
      }
    }
  }, [_vm.showTaskLockIcon ? _c('div', {
    staticClass: "svg-icon color lock",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.lock)
    }
  }) : _c('div', {
    staticClass: "svg-icon mb-1",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.gold)
    }
  }), _c('div', {
    staticClass: "small-text"
  }, [_vm._v(" " + _vm._s(_vm.task.value) + " ")])]) : _vm._e()]), _vm.task.group.id && !_vm.isOpenTask ? _c('approval-footer', {
    attrs: {
      "task": _vm.task,
      "group": _vm.group
    }
  }) : _vm._e()], 1)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "svg-icon",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.checklist)
    }
  });
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "tags-popover-title"
  }, [_vm._v(" " + _vm._s(`${_vm.$t('tags')}:`) + " ")]);

}]

export { render, staticRenderFns }