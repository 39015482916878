var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "low-health",
      "title": _vm.$t('losingHealthWarning'),
      "size": "md",
      "hide-footer": true
    }
  }, [_c('div', {
    staticClass: "modal-body"
  }, [_c('div', {
    staticClass: "col-12 text-center"
  }, [_c('div', {
    staticClass: "meter-label",
    attrs: {
      "tooltip": _vm.$t('health')
    }
  }, [_c('span', {
    staticClass: "glyphicon glyphicon-heart"
  })]), _c('div', {
    staticClass: "meter health",
    attrs: {
      "tooltip": Math.round(_vm.user.stats.hp * 100) / 100
    }
  }, [_c('div', {
    staticClass: "bar",
    style: _vm.barStyle
  }), _c('span', {
    staticClass: "meter-text value"
  }, [_vm._v(_vm._s(_vm.healthLeft))])])]), _c('div', {
    staticClass: "col-12"
  }, [_c('avatar', {
    attrs: {
      "member": _vm.user,
      "avatar-only": true,
      "with-background": true
    }
  })], 1), _c('div', {
    staticClass: "col-12"
  }, [_c('p', [_vm._v(_vm._s(_vm.$t('losingHealthWarning2')))]), _c('h4', [_vm._v(_vm._s(_vm.$t('toRegainHealth')))]), _c('ul', [_c('li', {
    staticClass: "spaced"
  }, [_vm._v(" " + _vm._s(_vm.$t('lowHealthTips1')) + " ")]), _c('li', {
    staticClass: "spaced"
  }, [_vm._v(" " + _vm._s(_vm.$t('lowHealthTips2')) + " ")])]), _c('h4', [_vm._v(_vm._s(_vm.$t('losingHealthQuickly')))]), _c('ul', [_c('li', {
    staticClass: "spaced"
  }, [_vm._v(" " + _vm._s(_vm.$t('lowHealthTips3')) + " ")]), _c('li', {
    staticClass: "spaced"
  }, [_vm._v(" " + _vm._s(_vm.$t('lowHealthTips4')) + " ")])]), _c('h4', [_vm._v(_vm._s(_vm.$t('goodLuck')))])])]), _c('div', {
    staticClass: "modal-footer"
  }, [_c('div', {
    staticClass: "col-12 text-center"
  }, [_c('button', {
    staticClass: "btn btn-primary",
    on: {
      "click": function ($event) {
        return _vm.acknowledgeHealthWarning();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('ok')) + " ")])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }