var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "report-profile",
      "title": _vm.$t('reportPlayer'),
      "hide-footer": !_vm.hasPermission(_vm.user, 'moderator'),
      "size": "md"
    }
  }, [_c('div', {
    attrs: {
      "slot": "modal-header"
    },
    slot: "modal-header"
  }, [_c('h2', {
    staticClass: "mt-2 mb-0"
  }, [_vm._v(" " + _vm._s(_vm.$t('reportPlayer')) + " ")]), _c('close-x', {
    on: {
      "close": function ($event) {
        return _vm.close();
      }
    }
  })], 1), _c('div', [_c('blockquote', [_c('strong', [_vm._v(" " + _vm._s(_vm.displayName) + " ")]), _c('p', {
    staticClass: "mb-0"
  }, [_vm._v(" " + _vm._s(_vm.username) + " ")])]), _c('div', [_c('strong', [_vm._v(_vm._s(_vm.$t('whyReportingPlayer')))]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.reportComment,
      expression: "reportComment"
    }],
    staticClass: "mt-2 form-control",
    attrs: {
      "placeholder": _vm.$t('whyReportingPlayerPlaceholder')
    },
    domProps: {
      "value": _vm.reportComment
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.reportComment = $event.target.value;
      }
    }
  })]), _c('p', {
    staticClass: "mb-2",
    domProps: {
      "innerHTML": _vm._s(_vm.$t('playerReportModalBody', _vm.abuseFlagModalBody))
    }
  })]), _c('div', {
    staticClass: "footer text-center d-flex flex-column"
  }, [_c('button', {
    staticClass: "btn btn-danger mx-auto mb-3",
    class: {
      disabled: !_vm.reportComment
    },
    attrs: {
      "disabled": !_vm.reportComment
    },
    on: {
      "click": function ($event) {
        return _vm.reportAbuse();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('report')) + " ")]), _c('a', {
    staticClass: "cancel-link",
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.close();
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('cancel')))])]), _c('div', {
    attrs: {
      "slot": "modal-footer"
    },
    slot: "modal-footer"
  }, [_c('div', {
    staticClass: "d-flex",
    on: {
      "click": function ($event) {
        return _vm.resetFlags();
      }
    }
  }, [_vm._m(0), _c('a', [_vm._v("Reset Flags")])])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "svg-icon icon-16 color my-auto mr-2",
    domProps: {
      "innerHTML": _vm._s(_vm.icons.report)
    }
  });

}]

export { render, staticRenderFns }