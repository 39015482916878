var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "streak",
      "title": _vm.$t('streakAchievement'),
      "size": "md",
      "hide-footer": true
    }
  }, [_c('div', {
    staticClass: "modal-body"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('achievement-avatar', {
    staticClass: "avatar"
  })], 1), _c('div', {
    staticClass: "col-6 offset-3 text-center"
  }, [_vm.user.achievements.streak === 1 ? _c('h3', [_vm._v(" " + _vm._s(_vm.$t('firstStreakAchievement')) + " ")]) : _vm._e(), _vm.user.achievements.streak > 1 ? _c('h3', [_vm._v(" " + _vm._s(_vm.$t('streakAchievementCount', {
    streaks: _vm.user.achievements.streak
  })) + " ")]) : _vm._e(), _c('p', [_vm._v(_vm._s(_vm.$t('twentyOneDays')))]), _c('p', [_vm._v(_vm._s(_vm.$t('dontBreakStreak')))]), _c('br'), _c('button', {
    staticClass: "btn btn-primary",
    on: {
      "click": function ($event) {
        return _vm.close();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('dontStop')) + " ")]), _c('div', {
    staticClass: "checkbox"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.user.preferences.suppressModals.streak,
      expression: "user.preferences.suppressModals.streak"
    }],
    attrs: {
      "id": "user-preferences-suppressModals-streak",
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.user.preferences.suppressModals.streak) ? _vm._i(_vm.user.preferences.suppressModals.streak, null) > -1 : _vm.user.preferences.suppressModals.streak
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.user.preferences.suppressModals.streak,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.user.preferences.suppressModals, "streak", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.user.preferences.suppressModals, "streak", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.user.preferences.suppressModals, "streak", $$c);
        }
      }, _vm.suppressModals]
    }
  }), _c('label', {
    attrs: {
      "for": "user-preferences-suppressModals-streak"
    }
  }, [_vm._v(_vm._s(_vm.$t('dontShowAgain')))])])])]), _c('achievement-footer')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }