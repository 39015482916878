var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "popover-box"
  }, [_c('div', {
    staticClass: "clearfix toggle-switch-outer"
  }, [_vm.label ? _c('div', {
    staticClass: "float-left toggle-switch-description",
    class: {
      'bold': _vm.boldLabel
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.label))])]) : _vm._e(), _vm.hoverText ? _c('span', {
    staticClass: "svg-icon inline icon-16 float-left",
    attrs: {
      "id": _vm.containerId
    },
    domProps: {
      "innerHTML": _vm._s(_vm.icons.information)
    }
  }) : _vm._e(), _c('div', {
    staticClass: "toggle-switch float-left"
  }, [_c('input', {
    staticClass: "toggle-switch-checkbox",
    attrs: {
      "id": _vm.toggleId,
      "type": "checkbox"
    },
    domProps: {
      "checked": _vm.isChecked,
      "value": _vm.value
    },
    on: {
      "change": _vm.handleChange
    }
  }), _c('label', {
    staticClass: "toggle-switch-label",
    attrs: {
      "for": _vm.toggleId
    }
  }, [_c('span', {
    staticClass: "toggle-switch-inner"
  }), _c('span', {
    staticClass: "toggle-switch-switch",
    attrs: {
      "tabindex": "0"
    },
    on: {
      "focus": _vm.handleFocus,
      "blur": _vm.handleBlur,
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "space", 32, $event.key, [" ", "Spacebar"])) return null;
        return _vm.handleSpace.apply(null, arguments);
      }
    }
  })])])]), _vm.hoverText ? _c('b-popover', {
    attrs: {
      "target": _vm.containerId,
      "triggers": "hover",
      "placement": "top"
    }
  }, [_c('div', {
    staticClass: "popover-content-text"
  }, [_vm._v(" " + _vm._s(_vm.hoverText) + " ")])]) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }